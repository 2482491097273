import React from "react"

import { RealisationsContainer } from "sections/Realisations/RealisationsContainer"
import { MainSection } from "sections/MainSection"
import SEO from "components/seo"

const Realisations = () => {
  return (
    <>
      <SEO title="realizacje - montaż i serwis klimatyzacji" />
      <MainSection short>
      </MainSection>
      <RealisationsContainer />
    </>
  )
}

export default Realisations
