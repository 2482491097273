import {graphql, useStaticQuery} from "gatsby"

export const useRealisationsImages = () => {
  const images = useStaticQuery(graphql`
      query {
          first: file(relativePath: { eq: "images/Realisations/001.png" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _101: file(relativePath: { eq: "images/Realisations/001/001.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _102: file(relativePath: { eq: "images/Realisations/001/002.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _103: file(relativePath: { eq: "images/Realisations/001/003.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _104: file(relativePath: { eq: "images/Realisations/001/004.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _201: file(relativePath: { eq: "images/Realisations/002/001.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _202: file(relativePath: { eq: "images/Realisations/002/002.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _203: file(relativePath: { eq: "images/Realisations/002/003.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _204: file(relativePath: { eq: "images/Realisations/002/004.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _205: file(relativePath: { eq: "images/Realisations/002/005.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _206: file(relativePath: { eq: "images/Realisations/002/006.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _301: file(relativePath: { eq: "images/Realisations/003/001.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _302: file(relativePath: { eq: "images/Realisations/003/002.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _401: file(relativePath: { eq: "images/Realisations/004/001.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _402: file(relativePath: { eq: "images/Realisations/004/002.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _403: file(relativePath: { eq: "images/Realisations/004/003.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 720, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _501: file(relativePath: { eq: "images/Realisations/005/001.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _502: file(relativePath: { eq: "images/Realisations/005/002.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
          _503: file(relativePath: { eq: "images/Realisations/005/003.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _505: file(relativePath: { eq: "images/Realisations/005/005.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _506: file(relativePath: { eq: "images/Realisations/005/006.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _601: file(relativePath: { eq: "images/Realisations/006/001.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _602: file(relativePath: { eq: "images/Realisations/006/002.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _701: file(relativePath: { eq: "images/Realisations/007/001.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _702: file(relativePath: { eq: "images/Realisations/007/002.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _703: file(relativePath: { eq: "images/Realisations/007/003.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _704: file(relativePath: { eq: "images/Realisations/007/004.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _705: file(relativePath: { eq: "images/Realisations/007/005.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _706: file(relativePath: { eq: "images/Realisations/007/006.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _801: file(relativePath: { eq: "images/Realisations/008/001.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _802: file(relativePath: { eq: "images/Realisations/008/002.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _803: file(relativePath: { eq: "images/Realisations/008/003.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _901: file(relativePath: { eq: "images/Realisations/009/001.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _902: file(relativePath: { eq: "images/Realisations/009/002.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1001: file(relativePath: { eq: "images/Realisations/010/001.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1002: file(relativePath: { eq: "images/Realisations/010/002.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1101: file(relativePath: { eq: "images/Realisations/011/001.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1102: file(relativePath: { eq: "images/Realisations/011/002.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1103: file(relativePath: { eq: "images/Realisations/011/003.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1201: file(relativePath: { eq: "images/Realisations/012/001.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1202: file(relativePath: { eq: "images/Realisations/012/002.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1301: file(relativePath: { eq: "images/Realisations/013/001.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1302: file(relativePath: { eq: "images/Realisations/013/002.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1303: file(relativePath: { eq: "images/Realisations/013/003.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1304: file(relativePath: { eq: "images/Realisations/013/004.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1401: file(relativePath: { eq: "images/Realisations/014/001.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1402: file(relativePath: { eq: "images/Realisations/014/002.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1403: file(relativePath: { eq: "images/Realisations/014/003.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1404: file(relativePath: { eq: "images/Realisations/014/004.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1501: file(relativePath: { eq: "images/Realisations/015/001.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
          _1502: file(relativePath: { eq: "images/Realisations/015/002.jpg" }) {
              childImageSharp {
                  fluid(maxHeight: 407, quality: 100) {
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                  }
              }
          }
      }
  `)
  return images
}
