import React from "react"
import { Realisation } from "sections/Realisations/Realisation"
import { useRealisationsData } from "shared/Hooks/Realisations"

const RealisationsContainer = () => {
  const data = useRealisationsData()
  return (
    <div>
      {data.map((item, index) =>
        index % 2 === 0 ? (
          <Realisation key={index} left data={{id: index, ...item}} />
        ) : (
          <Realisation key={index} data={{id: index, ...item}} />
        )
      )}
    </div>
  )
}

export { RealisationsContainer }
