import Image                   from "gatsby-image"
import React                   from "react"
import {useRealisationsImages} from "./useRealisationsImages"

const useRealisationsData = () => {
  const imgs = useRealisationsImages()
  return [
    {
      index:  "01",
      href:   "/realizacje",
      title:  "Salon Fryzjerski - Kraków",
      desc_short:
              "Realizacja w salonie fryzjerskim obejmowała dobór urządzenia oraz montaż. Urządzenie - Rotenso Imoto 3,5 kW.",
      description:
              "Realizacja w salonie fryzjerskim obejmowała dobór urządzenia oraz montaż. Urządzenie - Rotenso Imoto 3,5 kW.",
      images: [
        <Image fluid={imgs._101.childImageSharp.fluid}/>,
        <Image fluid={imgs._102.childImageSharp.fluid}/>,
        <Image fluid={imgs._103.childImageSharp.fluid}/>,
        <Image fluid={imgs._104.childImageSharp.fluid}/>,
      ],
    },
    {
      index:  "02",
      href:   "/realizacje",
      title:  "Kraków Bieżanów",
      desc_short:
              "Urządzenia to Samsung Wind-Free Optimum 2,5 kW które idealnie sprawdzają się w sypialni. Wind Free - to możliwość utrzymywania temperatury w pomieszczeniu z unikalną (spotykaną tylko w produktach Samsunga) dystrybucją powietrza za pośrednictwem tysięcy mikrootworów znajdujących się w przedniej części obudowy klimatyzatora.",
      description:
              "Urządzenia to Samsung Wind-Free Optimum 2,5 kW które idealnie sprawdzają się w sypialni. Wind Free - to możliwość utrzymywania temperatury w pomieszczeniu z unikalną (spotykaną tylko w produktach Samsunga) dystrybucją powietrza za pośrednictwem tysięcy mikrootworów znajdujących się w przedniej części obudowy klimatyzatora.",
      images: [
        <Image fluid={imgs._201.childImageSharp.fluid}/>,
        <Image fluid={imgs._202.childImageSharp.fluid}/>,
        <Image fluid={imgs._203.childImageSharp.fluid}/>,
        <Image fluid={imgs._204.childImageSharp.fluid}/>,
        <Image fluid={imgs._205.childImageSharp.fluid}/>,
        <Image fluid={imgs._206.childImageSharp.fluid}/>,
      ],
    },
    {
      index:  "03",
      href:   "/realizacje",
      title:  "Kłaj",
      desc_short:
              "Realizacja w miejscowości Kłaj objęła dobór urządzenia oraz montaż. Urządzenie - AUX Freedom 3,5 kW",
      description:
              "Realizacja w miejscowości Kłaj objęła dobór urządzenia oraz montaż. Urządzenie - AUX Freedom 3,5 kW",
      images: [
        <Image fluid={imgs._301.childImageSharp.fluid}/>,
        <Image fluid={imgs._302.childImageSharp.fluid}/>,
      ],
    },
    {
      index:  "04",
      href:   "/realizacje",
      title:  "Wola Batorska",
      desc_short:
              "Realizacja w miejscowości Wola Batorska - dobór urządzenia oraz montaż. Urządzenie AUX J-Smart 5,4 kW bogato wyposażone - idealne do pomieszczeń do 60m2. W wyposażeniu grzałka tacy ociekowej co gwarantuje wydajną pracę w trybie ogrzewania.",
      description:
              "Realizacja w miejscowości Wola Batorska - dobór urządzenia oraz montaż. Urządzenie AUX J-Smart 5,4 kW bogato wyposażone - idealne do pomieszczeń do 60m2. W wyposażeniu grzałka tacy ociekowej co gwarantuje wydajną pracę w trybie ogrzewania.",
      images: [
        <Image fluid={imgs._401.childImageSharp.fluid}/>,
        <Image fluid={imgs._402.childImageSharp.fluid}/>,
        <Image fluid={imgs._403.childImageSharp.fluid}/>,
      ],
    },
    {
      index:  "05",
      href:   "/realizacje",
      title:  "Kraków Bieżanów",
      desc_short:
              "Klimatyzator Gree Amber Standard 3,5 kW posiada ponadprzeciętne parametry techniczne, wyróżniające go spośród urządzeń tej klasy. Szeroki zakres pracy (do -22°C przy grzaniu oraz 43°C przy chłodzeniu) sprawia, że sprawdza się on praktycznie w każdych warunkach.",
      description:
              "Klimatyzator Gree Amber Standard 3,5 kW posiada ponadprzeciętne parametry techniczne, wyróżniające go spośród urządzeń tej klasy. Szeroki zakres pracy (do -22°C przy grzaniu oraz 43°C przy chłodzeniu) sprawia, że sprawdza się on praktycznie w każdych warunkach.",
      images: [
        <Image fluid={imgs._501.childImageSharp.fluid}/>,
        <Image fluid={imgs._502.childImageSharp.fluid}/>,
        <Image fluid={imgs._503.childImageSharp.fluid}/>,
        // <Image fluid={imgs._504.childImageSharp.fluid} />,
        <Image fluid={imgs._505.childImageSharp.fluid}/>,
        <Image fluid={imgs._506.childImageSharp.fluid}/>,
      ],
    },
    {
      index:      "06",
      href:       "/realizacje",
      title:      "Kraków Serwerownia",
      description:
                  <>
                    <strong>Panasonic KIT-Z35TKEA Klimatyzator do Serwerowni.</strong> <br/>
                    Klimatyzatory do montażu ściennego przeznaczone są przede wszystkim do zastosowań profesjonalnych, np. w pomieszczeniach serwerowni, których schładzanie jest konieczne nawet przy niskich temperaturach zewnętrznych, nawet przy temperaturze -20°C. <br/>
                    Ponadto urządzenia te wyposażono w automatyczny system przełączania, umożliwiający utrzymanie stałej temperatury wewnętrznej również przy gwałtownych zmianach temperatury zewnętrznej.
                  </>,
      desc_short: "Panasonic KIT-Z35TKEA Klimatyzator do Serwerowni.Klimatyzatory do montażu ściennego przeznaczone są przede wszystkim do zastosowań profesjonalnych, np. w pomieszczeniach serwerowni, których schładzanie jest konieczne nawet przy niskich temperaturach zewnętrznych, nawet przy temperaturze -20°C. Ponadto urządzenia te wyposażono w automatyczny system przełączania, umożliwiający utrzymanie stałej temperatury wewnętrznej również przy gwałtownych zmianach temperatury zewnętrznej.",
      images:     [
        <Image fluid={imgs._601.childImageSharp.fluid}/>,
        <Image fluid={imgs._602.childImageSharp.fluid}/>,
      ],
    },
    {
      index:       "07",
      href:        "/realizacje",
      title:       "Kraków - Bieżanów",
      desc_short:  "GREE Lomo Luxury Plus 3,5kW Klimatyzator jest rozbudowaną o nowe możliwości wersją popularnego modelu Gree z niezawodnej serii Lomo. Wysokie parametry pracy i różnorodne funkcje dostępne w standardzie potwierdzają jego wysoką klasę Lux. Dzięki unowocześnionej konstrukcji model zyskał na Plus dodatkowe funkcjonalności, takie jak: sterowanie nawiewem powietrza w pionie i poziomie, więcej biegów wentylatora i trybów snu.",
      description: <>
                     <strong>GREE Lomo Luxury Plus 3,5kW</strong> <br/>
                     Klimatyzator jest rozbudowaną o nowe możliwości wersją popularnego modelu Gree z niezawodnej serii Lomo. Wysokie parametry pracy i różnorodne funkcje dostępne w standardzie potwierdzają jego wysoką klasę Lux. Dzięki unowocześnionej konstrukcji model zyskał na Plus dodatkowe funkcjonalności, takie jak: sterowanie nawiewem powietrza w pionie i poziomie, więcej biegów wentylatora i trybów snu.
                   </>,

      images: [
        <Image fluid={imgs._701.childImageSharp.fluid}/>,
        <Image fluid={imgs._702.childImageSharp.fluid}/>,
        <Image fluid={imgs._703.childImageSharp.fluid}/>,
        <Image fluid={imgs._704.childImageSharp.fluid}/>,
        <Image fluid={imgs._705.childImageSharp.fluid}/>,
        <Image fluid={imgs._706.childImageSharp.fluid}/>,
      ],
    },
    {
      index:       "08",
      href:        "/realizacje",
      title:       "Wola Zachariaszowska",
      desc_short:  "GREE Amber Standard Silver 3,5kW Luksus w standardzie! Imponuje parametrami, przyciąga kolorami! Klimatyzator Amber Standard to elegancki wygląd, wysokie parametry oraz szeroki wachlarz funkcji. Dzięki dostępnym aż 4 wersjom kolorystycznym model ten świetnie dopełni aranżację każdego wnętrza. Klimatyzator jest idealnym wyborem nie tylko do chłodzenia, ale również ogrzewania pomieszczeń. Wyróżniają go fenomenalne możliwości pracy, a przy tym wysoka klasa efektywności energetycznej A+++. W tym przypadku klimatyzator idealnie komponuje się z kolorem ścian.",
      description: <>
                     <strong>GREE Amber Standard Silver 3,5kW.</strong> <br/>
                     Luksus w standardzie! Imponuje parametrami, przyciąga kolorami! Klimatyzator Amber Standard to elegancki wygląd, wysokie parametry oraz szeroki wachlarz funkcji. Dzięki dostępnym aż 4 wersjom kolorystycznym model ten świetnie dopełni aranżację każdego wnętrza. Klimatyzator jest idealnym wyborem nie tylko do chłodzenia, ale również ogrzewania pomieszczeń. Wyróżniają go fenomenalne możliwości pracy, a przy tym wysoka klasa efektywności energetycznej A+++. <br/>
                     W tym przypadku klimatyzator idealnie komponuje się z kolorem ścian.
                   </>,
      images:      [
        <Image fluid={imgs._801.childImageSharp.fluid}/>,
        <Image fluid={imgs._802.childImageSharp.fluid}/>,
        <Image fluid={imgs._803.childImageSharp.fluid}/>,
      ],
    },
    {
      index:       "09",
      href:        "/realizacje",
      title:       "Kraków - Pogórze",
      desc_short:  "Klimatyzator LG DUALCOOL z oczyszczaniem powietrza 3.5kW Klimatyzator LG został wzbogacony o system oczyszczania 2 w 1 (chłodzenie powietrzem i filtracja powietrza), który może odfiltrować drobny kurz, zarazki i zanieczyszczenia powietrza, takie jak PM 1.0.",
      description: <>
                     <strong>Klimatyzator LG DUALCOOL z oczyszczaniem powietrza 3.5kW</strong> <br/>
                     Klimatyzator LG został wzbogacony o system oczyszczania 2 w 1 (chłodzenie powietrzem i filtracja powietrza), który może odfiltrować drobny kurz, zarazki i zanieczyszczenia powietrza, takie jak PM 1.0.
                   </>,
      images:      [
        <Image fluid={imgs._901.childImageSharp.fluid}/>,
        <Image fluid={imgs._902.childImageSharp.fluid}/>,
      ],
    },
    {
      index:       "10",
      href:        "/realizacje",
      title:       "Kraków - Podgórze 2",
      desc_short:  "Klimatyzator ścienny G-Tech Silver 2,7kW Nowość roku 2020. Dzięki innowacyjnej konstrukcji model ten umożliwia łatwy i szybki demontaż poszczególnych podzespołów oraz ich dokładne czyszczenie, co wpływa na jego żywotność i wydajność. Urządzenie charakteryzują ponadto bogate opcje sterowania oraz stosowana tylko dla G-Tech nowa żaluzja kontrolująca nawiew powietrza jednocześnie w kierunku pionowym, jak i poziomym. G-Tech charakteryzuje się najwyższą klasą energetyczną chłodzenia A+++ i bardzo wysoką dla grzania A++.",
      description: <>
                     <strong>Klimatyzator ścienny G-Tech Silver 2,7kW</strong> <br/>
                     Nowość roku 2020. Dzięki innowacyjnej konstrukcji model ten umożliwia łatwy i szybki demontaż poszczególnych podzespołów oraz ich dokładne czyszczenie, co wpływa na jego żywotność i wydajność. Urządzenie charakteryzują ponadto bogate opcje sterowania oraz stosowana tylko dla G-Tech nowa żaluzja kontrolująca nawiew powietrza jednocześnie w kierunku pionowym, jak i poziomym. G-Tech charakteryzuje się najwyższą klasą energetyczną chłodzenia A+++ i bardzo wysoką dla grzania A++.
                   </>,
      images:      [
        <Image fluid={imgs._1001.childImageSharp.fluid}/>,
        <Image fluid={imgs._1002.childImageSharp.fluid}/>,
      ],
    },
    {
      index:       "11",
      href:        "/realizacje",
      title:       "Kraków - Podgórze 3",
      desc_short:  <>
                     <strong>SAMSUNG MULTI SPLIT</strong> <br/>
                     Samsung Luzon 3,5kW <br/>
                     Samsung Wind-Free Comfort 3,5kW - sypialnia
                   </>,
      description: <>
                     <strong>SAMSUNG MULTI SPLIT</strong> <br/>
                     Samsung Luzon 3,5kW <br/>
                     Samsung Wind-Free Comfort 3,5kW - sypialnia
                   </>,
      images:      [
        <Image fluid={imgs._1101.childImageSharp.fluid}/>,
        <Image fluid={imgs._1102.childImageSharp.fluid}/>,
        <Image fluid={imgs._1103.childImageSharp.fluid}/>,
      ],
    },
    {
      index:       "12",
      href:        "/realizacje",
      title:       "Zielonki",
      desc_short:  "GREE Pular 3,2kW To klasyczny w swym wyglądzie model dostępny w wyjątkowo niskiej cenie oferuje jednocześnie wysoką energooszczędność, szeroką funkcjonalność i pełen komfort. Dzięki szerokim możliwościom sterowania jego obsługa jest prosta i wygodna. Wyposażony został w standardzie w pilot bezprzewodowy oraz moduł WiFi. Urządzenie posiada 3 tryby snu, 7 biegów wentylatora oraz automatycznie żaluzje pionowe i poziome.",
      description: <>
                     <strong>GREE Pular 3,2kW </strong> <br/>
                     To klasyczny w swym wyglądzie model dostępny w wyjątkowo niskiej cenie oferuje jednocześnie wysoką energooszczędność, szeroką funkcjonalność i pełen komfort. Dzięki szerokim możliwościom sterowania jego obsługa jest prosta i wygodna. Wyposażony został w standardzie w pilot bezprzewodowy oraz moduł WiFi. Urządzenie posiada 3 tryby snu, 7 biegów wentylatora oraz automatycznie żaluzje pionowe i poziome.
                   </>,
      images:      [
        <Image fluid={imgs._1201.childImageSharp.fluid}/>,
        <Image fluid={imgs._1202.childImageSharp.fluid}/>,
      ],
    },
    {
      index:      "13",
      href:       "/realizacje",
      title:      "Kraków - Wzgórza Krzesławickie",
      desc_short: "SAMSUNG Wind-Free Comfort 3,5kW - urządzenia zamontowane w salonie i sypialni. Zapewnij sobie przyjemny chłód dzięki technologii Wind-Free™. Technologia ta pozwala delikatnie i cicho rozprowadzać powietrze przez 23 000 mikrootworów, co sprawia, że nie poczujesz na skórze nieprzyjemnych zimnych powiewów. Zaawansowana konfiguracja przepływu powietrza umożliwia bardziej równomierne chłodzenie na większej przestrzeni.",
      description:
                  <>
                    <strong>SAMSUNG Wind-Free Comfort 3,5kW </strong> - urządzenia zamontowane w salonie i sypialni.<br/>
                    Zapewnij sobie przyjemny chłód dzięki technologii Wind-Free™. Technologia ta pozwala delikatnie i cicho rozprowadzać powietrze przez 23 000 mikrootworów, co sprawia, że nie poczujesz na skórze nieprzyjemnych zimnych powiewów. Zaawansowana konfiguracja przepływu powietrza umożliwia bardziej równomierne chłodzenie na większej przestrzeni.
                  </>,
      images:     [
        <Image fluid={imgs._1301.childImageSharp.fluid}/>,
        <Image fluid={imgs._1302.childImageSharp.fluid}/>,
        <Image fluid={imgs._1303.childImageSharp.fluid}/>,
        <Image fluid={imgs._1304.childImageSharp.fluid}/>,
      ],
    },
    {
      index:      "14",
      href:       "/realizacje",
      title:      "Kraków",
      desc_short: "Układ MULTI SPLIT marki GREE GREE Lomo Luxury Plus 2,7kW GREE Amber Standard Silver 2,7kW",
      description:
                  <>
                    <strong>Układ MULTI SPLIT marki GREE</strong> <br/>
                    GREE Lomo Luxury Plus 2,7kW <br/>
                    GREE Amber Standard Silver 2,7kW
                  </>,
      images:     [
        <Image fluid={imgs._1401.childImageSharp.fluid}/>,
        <Image fluid={imgs._1402.childImageSharp.fluid}/>,
        <Image fluid={imgs._1403.childImageSharp.fluid}/>,
        <Image fluid={imgs._1404.childImageSharp.fluid}/>,
      ],
    },
    {
      index:       "15",
      href:        "/realizacje",
      title:       "Kraków - Osiedle Piastów",
      desc_short:  "GREE Lomo Luxury 2,6kW. Jego główną zaletą jest wszechstronność. Możliwość grzania nawet do -22°C, funkcja gorącego startu oraz inteligentne odszranianie sprawiają, że urządzenie to sprawdzi się również jako źródło ciepła. Możliwość obsługi przez Wi-Fi, jak i bogata oferta rozwiązań dodatkowych w zakresie sterowania sprawiają, że klimatyzator ten jest doskonałym wyborem dla osób ceniących sobie wygodę użytkowania.",
      description: <>
                     <strong>GREE Lomo Luxury 2,6kW</strong> <br/>
                     Jego główną zaletą jest wszechstronność. Możliwość grzania nawet do -22°C, funkcja gorącego startu oraz inteligentne odszranianie sprawiają, że urządzenie to sprawdzi się również jako źródło ciepła. Możliwość obsługi przez Wi-Fi, jak i bogata oferta rozwiązań dodatkowych w zakresie sterowania sprawiają, że klimatyzator ten jest doskonałym wyborem dla osób ceniących sobie wygodę użytkowania
                   </>,

      images: [
        <Image fluid={imgs._1501.childImageSharp.fluid}/>,
        <Image fluid={imgs._1502.childImageSharp.fluid}/>,
      ],
    },
  ]
}

export {useRealisationsData}
