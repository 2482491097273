import styled from "styled-components"
import { OverflowWrapper } from "shared/Styles/Wrappers"
import { IconPathStroke } from "shared/Styles/Icons"

export const StyledOverflowWrapper = styled(OverflowWrapper)`
  background: ${props => (props.left ? props.theme.color.white : "#fff")};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding: 30px 0;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    flex-direction: ${props => (props.left ? "row" : "row-reverse")};
    justify-content: space-between;
    padding: 80px 0;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.laptopSmall}) {
    padding: 120px 0;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  padding-left: 30px;
  margin-top: 26px;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.phoneSmall}) {
    width: 290px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.phoneBig}) {
    width: 100%;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    width: 360px;
    margin-top: 0;
    padding-left: 0;
  }

    @media screen and (min-width: ${({ theme }) =>
            theme.breakpoints.tabletBig}) {
        width: 500px;
    }
`

export const TitleWrapper = styled.div``

export const DescriptionWrapper = styled.div`
  margin-top: 16px;
  flex-grow: 1;
`

export const NavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 14px;
  margin-top: 30px;
  @media screen and (min-width: ${props=>props.theme.breakpoints.laptopSmall}) {
      margin-top: 0;
  }
`

export const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.tabletSmall}) {
    width: 50%;
  }
`

export const SlideWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 10px;
    background: ${props =>
      props.left ? props.theme.color.primary : props.theme.color.secondary};
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  .gatsby-image-wrapper {
    min-width: 280px;
    height: 100%;
  }
`

export const ArrowWrapper = styled.div`
  cursor: pointer;
  &:first-child {
    margin-right: 36px;
  }
  &.reverse {
    transform: rotateZ(180deg);
  }
  &:hover {
    ${IconPathStroke} {
      stroke: ${props => props.theme.color.primary};
    }
  }
`

export const PaginationWrapper = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-left: 60px;
  color: ${props => props.theme.color.gray};
`

export const ActiveSlidePagination = styled.span`
  color: ${props => props.theme.color.primary};
`
