import React, { useState } from "react"

import {
  StyledOverflowWrapper,
  Container,
  ContentWrapper,
  TitleWrapper,
  DescriptionWrapper,
  NavigationWrapper,
  SliderWrapper,
  SlideWrapper,
  ArrowWrapper,
  PaginationWrapper,
  ActiveSlidePagination,
} from "./styles"
import { PageWrapper } from "shared/Styles/Wrappers"
import { ArrowLong } from "components/Icons"
import { MediumHeader, Paragraph } from "components/Typography"
import { CONFIG } from "./consts"
import { withTheme } from "styled-components"
import { Swiper, SwiperSlide } from "swiper/react"

const Realisation = withTheme(({ left, theme, data }) => {
  const [slidesCount, setSlidesCount] = useState(0)
  const [activeSlide, setActiveSlide] = useState(0)
  return (
    <>
      <StyledOverflowWrapper left={left}>
        <PageWrapper>
          <Container left={left}>
            <ContentWrapper>
              <TitleWrapper>
                <MediumHeader>{data.title}</MediumHeader>
              </TitleWrapper>
              <DescriptionWrapper>
                <Paragraph>{data.description}</Paragraph>
              </DescriptionWrapper>
              <NavigationWrapper>
                <ArrowWrapper className={`sliderPrev${data.id} reverse`}>
                  <ArrowLong width={"41px"} color={theme.color.gray} />
                </ArrowWrapper>
                <ArrowWrapper className={`sliderNext${data.id}`}>
                  <ArrowLong width={"41px"} color={theme.color.gray} />
                </ArrowWrapper>
                <PaginationWrapper>
                  <ActiveSlidePagination>{activeSlide}</ActiveSlidePagination>/
                  {slidesCount}
                </PaginationWrapper>
              </NavigationWrapper>
            </ContentWrapper>
            <SliderWrapper>
              <Swiper
                {...CONFIG}
                onInit={slider => {
                  setSlidesCount(slider.slides.length - 2)
                }}
                navigation={{
                  nextEl: `.sliderNext${data.id}`,
                  prevEl: `.sliderPrev${data.id}`,
                }}
                onSlideChange={slider => setActiveSlide(slider.realIndex + 1)}
              >
                {data.images.map((image, index) => (
                  <SwiperSlide key={index} style={{ width: "100%" }}>
                    <SlideWrapper left={left}>{image}</SlideWrapper>
                  </SwiperSlide>
                ))}
              </Swiper>
            </SliderWrapper>
          </Container>
        </PageWrapper>
      </StyledOverflowWrapper>
    </>
  )
})

export { Realisation }
